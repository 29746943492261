import { useEffect, useState } from 'react'
import {
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material'
import { Formik } from 'formik'
import { useAuth } from 'context'
import * as controllers from 'controllers'
import {
  CompanyDto,
  WorkOrderDto,
  WorkOrderReleaseDto,
  WorkOrderReleaseTaskDto,
  WorkOrderReleaseTaskTimeEntryDto,
  WorkOrderReleaseTaskTypeDto,
  WorkOrderTaskMoveableTimeEntryDto
} from 'dtos'
import { useDebounce } from 'utils'

interface WorkOrderReleaseTaskMoveTimeEntryAddEditDialogProps {
  initialValues: WorkOrderTaskMoveableTimeEntryDto
  isLoading?: boolean
  onClose: () => void
  onSave: (values: WorkOrderTaskMoveableTimeEntryDto) => void
  open: boolean
  timeEntry: WorkOrderReleaseTaskTimeEntryDto
  company?: CompanyDto[]
  workOrder?: WorkOrderDto
  workOrderReleaseTask?: WorkOrderReleaseTaskDto[]
  workOrderTaskType?: WorkOrderReleaseTaskTypeDto[]
}

const companiesFilterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: CompanyDto) => option.name
})

export default function WorkOrderReleaseTaskMoveTimeEntryAddEditDialog({
  initialValues,
  isLoading,
  onClose,
  onSave,
  open,
  timeEntry,
  workOrder,
  company,
  workOrderReleaseTask
}: WorkOrderReleaseTaskMoveTimeEntryAddEditDialogProps) {
  const {
    permissions: { CAN_LOG_TIME_FOR_OTHER_USERS }
  } = useAuth()

  // Fetch all companies on component mount
  const [companies, setCompanies] = useState<CompanyDto[]>([])

  const [isGettingCompanies, setIsGettingCompanies] = useState<boolean>(false)

  const getCompaniesAutocomplete = useDebounce((search: string) => {
    setIsGettingCompanies(true)
    controllers
      .getCompanies({ page: 0, pageSize: 20, search, statuses: ['isActive'] })
      .then(response => {
        setCompanies(response.value)
      })
      .finally(() => {
        setIsGettingCompanies(false)
      })
  }, 300)

  const [workOrders, setWorkOrders] = useState<WorkOrderDto[]>([])

  const [workOrderRelease, setWorkOrderRelase] = useState<WorkOrderReleaseDto[]>([])

  const [workOrderReleaseTasks, setWorkOrderReleaseTasks] = useState<WorkOrderReleaseTaskDto[]>([])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          console.log(values)
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
      >
        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} alignItems='center'>
                <DialogTitle>Move Time Entry</DialogTitle>

                {/* Company Drop Down */}
                <Grid item xs={12} style={{ minWidth: 300 }}>
                  <Autocomplete
                    disabled={isLoading}
                    disableListWrap
                    noOptionsText={'Enter Company Name'}
                    options={companies}
                    getOptionLabel={option => option.name || ''}
                    filterOptions={companiesFilterOptions}
                    onBlur={handleBlur}
                    onChange={(_e, value) => {
                      setFieldValue('company', value || undefined)

                      if (value?.id) {
                        controllers.getWorkOrders({ page: 0, pageSize: 100, companyId: value.id }).then(response => {
                          setWorkOrders(response.value)
                        })
                      }
                    }}
                    onInputChange={(_e, value, _reason) => {
                      getCompaniesAutocomplete(value)
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={Boolean(touched.company && errors.company)}
                        helperText={touched.company && errors.company}
                        label='Company Name'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isGettingCompanies && <CircularProgress size={24} />}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                    value={values.company || null}
                  />
                </Grid>

                {/* Work Order Drop Down */}
                <Grid item xs={12} style={{ minWidth: 300 }}>
                  <Autocomplete
                    disabled={isLoading || !values.company}
                    noOptionsText={'No Work Orders Found For Company'}
                    options={workOrders}
                    getOptionLabel={option => option.workOrderNumber?.toString() + ' - ' + option.project?.name || 'ERROR!'}
                    onBlur={handleBlur}
                    onChange={(_e, value) => {
                      setFieldValue('workOrder', value)
                      if (value?.id) {
                        controllers.getWorkOrderReleasesByWorkOrderId({ page: 0, pageSize: 100 }, value.id).then(response => {
                          setWorkOrderRelase(response.value)
                        })
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{ ...params.InputProps }}
                        error={Boolean(touched.workOrder && errors.workOrder)}
                        helperText={touched.workOrder && errors.workOrder}
                        label='Work Order'
                        name='workOrder'
                      />
                    )}
                    fullWidth
                    value={values.workOrder || null}
                  />
                </Grid>

                {/* Release Drop Down */}
                <Grid item xs={12} style={{ minWidth: 300 }}>
                  <Autocomplete
                    disabled={isLoading || !values.workOrder}
                    options={workOrderRelease}
                    noOptionsText={'No Releases Found For Work Order'}
                    getOptionLabel={option => option.releaseTitle?.toString() || 'ERROR!'}
                    onBlur={handleBlur}
                    onChange={(_e, value) => {
                      setFieldValue('workOrderRelease', value)
                      if (value?.id) {
                        controllers
                          .getWorkOrderReleaseTasksByWorkOrderReleaseId(
                            { page: 0, pageSize: 100 },
                            values.workOrder!.id!,
                            value.id
                          )
                          .then(response => {
                            setWorkOrderReleaseTasks(response.value)
                          })
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{ ...params.InputProps }}
                        error={Boolean(touched.workOrderRelease && errors.workOrderRelease)}
                        helperText={touched.workOrderRelease && errors.workOrderRelease}
                        label='Work Order Release'
                        name='workOrderRelease'
                      />
                    )}
                    fullWidth
                    value={values.workOrderRelease || null}
                  />
                </Grid>

                {/* Task Down */}
                <Grid item xs={12} style={{ minWidth: 300 }}>
                  <Autocomplete
                    disabled={isLoading || !values.workOrderRelease}
                    noOptionsText={'No Tasks Found For Release'}
                    options={workOrderReleaseTasks}
                    getOptionLabel={option => option.workToBeDone?.toString() || 'ERROR!'}
                    onBlur={handleBlur}
                    onChange={(_e, value) => {
                      setFieldValue('workOrderReleaseTask', value)
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{ ...params.InputProps }}
                        error={Boolean(touched.workOrderReleaseTask && errors.workOrderReleaseTask)}
                        helperText={touched.workOrderReleaseTask && errors.workOrderReleaseTask}
                        label='Task'
                        name='task'
                      />
                    )}
                    fullWidth
                    value={values.workOrderReleaseTask || null}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button color='inherit' disabled={isLoading} onClick={onClose} variant='text'>
                CANCEL
              </Button>
              <Button type='submit' variant='contained' disabled={isLoading}>
                SAVE
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
