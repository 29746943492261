import { apiClient } from '_app'
import { ApiResponse, OpportunityActivityDto } from 'dtos'

export const addActivity = (values: OpportunityActivityDto) =>
  apiClient
    .post<ApiResponse<OpportunityActivityDto>>('/api/opportunity-activities', {
      ...values,
      opportunity: { ...values.opportunity, opportunityActivity: [] }
    })
    .then(({ data }) => data)
