import { Button, Grid, TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { TimePunchAddEditDialog } from 'components'
import LabelField from 'components/LabelField'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListBody, ListFilters, ListHeader, ListRow, ListRowAction } from 'components/list'
import { useAuth, useLayout } from 'context'
import * as api from 'controllers'
import { PersonDto, TimePunchDto } from 'dtos'
import { useSnackbar } from 'notistack'
import { TimePunchesParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { formatDateTime, formatDateValue, getTimePunched, useDebounce } from 'utils'

export default function TimePunches() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const { enqueueSnackbar } = useSnackbar()
  const {
    permissions: { TIME_PUNCHES_ADD_EDIT },
    user
  } = useAuth()

  const getTimePunches = useDebounce((parameters: TimePunchesParameters) => {
    setIsLayoutLoading(true)
    api
      .getTimePunches(parameters)
      .then(res => {
        setTimePunches(res.value)
        setCount(res.totalCount ?? 0)
      })
      .catch((errors: string[]) => {
        errors.forEach(error => {
          enqueueSnackbar(error, { variant: 'error' })
        })
      })
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 300)

  const [isCreatingTimePunch, setIsCreatingTimePunch] = useState<boolean>(false)

  const [timePunch, setTimePunch] = useState<TimePunchDto>(new TimePunchDto())
  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])
  const [timePunches, setTimePunches] = useState<TimePunchDto[]>([])
  const [timePunchDialogOpen, setTimePunchDialogOpen] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [isGettingTeamMembers, setIsGettingTeamMembers] = useState<boolean>(false)
  const [isGettingTimePunches, setIsGettingTimePunches] = useState<boolean>(true)
  const [parameters, setParameters] = useState<TimePunchesParameters>({
    page: 0,
    pageSize: 999,
    personId: undefined,
    dateFrom: formatDateValue(new Date())
  })
  // #endregion

  useEffect(() => {
    setParameters({
      ...parameters,
      personId: user!.id
    })
  }, [user])

  // #region useEffect
  useEffect(() => {
    setTitle('Time Punches')

    setIsGettingTeamMembers(true)
    api
      .getPeople({
        page: 0,
        pageSize: 999,
        permission: 'TIME_CLOCK',
        statuses: ['isUser']
      })
      .then(({ value }) => {
        setTeamMembers(value)
      })
      .finally(() => {
        setIsGettingTeamMembers(false)
      })
  }, [])

  useEffect(() => {
    getTimePunches(parameters)
  }, [parameters])
  // #endregion

  return (
    <>
      <TimePunchAddEditDialog
        isLoading={isCreatingTimePunch}
        initialValues={timePunch}
        onClose={() => {
          setTimePunchDialogOpen(false)
        }}
        onSave={timePunch => {
          setIsCreatingTimePunch(true)

          const request = timePunch.id ? api.updateTimePunch : api.createTimePunch

          request(timePunch)
            .then(() => {
              enqueueSnackbar('Time Punch Saved Successfully!', { variant: 'success' })
              getTimePunches(parameters)
              setTimePunchDialogOpen(false)
            })
            .finally(() => {
              setIsCreatingTimePunch(false)
            })
        }}
        open={timePunchDialogOpen}
      />
      <List>
        <ListHeader>Time Punches</ListHeader>

        <ListFilters>
          <TextField
            label='Team Member'
            sx={{ minWidth: 200 }}
            onChange={e => {
              setParameters({
                ...parameters,
                personId: e.target.value ? JSON.parse(e.target.value).id : undefined
              })
            }}
            select
            size='small'
            value={
              parameters.personId
                ? JSON.stringify(teamMembers.filter(teamMember => teamMember.id === parameters.personId)[0])
                : ''
            }
          >
            {teamMembers
              ?.sort((a, b) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName))
              .map(teamMember => (
                <MenuItem value={JSON.stringify(teamMember)} key={teamMember.id}>
                  {teamMember.firstName + ' ' + teamMember.lastName}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            sx={{ minWidth: 200 }}
            InputLabelProps={{ shrink: true }}
            label='Date'
            onChange={e => {
              setParameters({ ...parameters, dateFrom: e.target.value })
            }}
            type='date'
            value={parameters.dateFrom ?? ''}
          />

          <LabelField
            label='Time Punched'
            value={getTimePunched(timePunches) + ''} //{timeEntries?.reduce((a, b) => a + b.hoursWorked!, 0) + ''}
          />

          {TIME_PUNCHES_ADD_EDIT && (
            <Button
              onClick={() => {
                setTimePunch({
                  ...new TimePunchDto(),
                  person: teamMembers.filter(teamMember => teamMember.id === parameters.personId)[0] ?? undefined
                })
                setTimePunchDialogOpen(true)
              }}
              variant='contained'
              size='medium'
              color='primary'
            >
              ADD NEW
            </Button>
          )}
        </ListFilters>

        <ListBody>
          {timePunches.map((timePunch, index) => (
            <ListRow
              key={timePunch.id}
              title={formatDateTime(timePunch.timePunchDateTime!)}
              subtitle1={index % 2 === 0 ? 'In' : 'Out'}
              subtitle2={
                timePunch.isManual ? `Created By ${timePunch.createdBy?.firstName} ${timePunch.createdBy?.lastName}` : ''
              }
              chips={[
                {
                  backgroundColor: '#fff6e0',
                  color: '#ffb400',
                  label: 'Manual',
                  when: Boolean(timePunch.isManual)
                }
              ]}
            >
              {TIME_PUNCHES_ADD_EDIT && (
                <ListRowAction
                  label='Edit'
                  onClick={() => {
                    setTimePunch(timePunch)
                    setTimePunchDialogOpen(true)
                  }}
                ></ListRowAction>
              )}
            </ListRow>
          ))}

          <Grid item xs={12}>
            <ExplanationAccordion>
              Ordered by Punch Date/Time Ascending. ||List default loads to Team Member/User signed in and todays Date. ||Time
              Punched shows total time accumulated for the date chosen.
            </ExplanationAccordion>
          </Grid>
        </ListBody>
      </List>
    </>
  )
}
