import { SearchOutlined } from '@mui/icons-material'
import { AppBar, Grid, IconButton, InputAdornment, Link, Menu, MenuItem, Slide, TextField, Toolbar } from '@mui/material'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import User from './User'
import { useAuth } from 'context'

type SearchTarget = 'Companies' | 'People' | 'Work Orders'

// This was previously used to map over to render the MUI <Menu> options below.
// const searchTargets: SearchTarget[] = ['Companies', 'People', 'Work Orders']

function IsSearchTarget(value: any): value is SearchTarget {
  return value === 'Companies' || value === 'People' || value === 'Work Orders'
}

interface AppHeaderProps {
  isMobile: boolean
  trigger: boolean
}

// This is called AppHeader as to not be confused with MUI's AppBar.
export default function AppHeader({ isMobile, trigger }: AppHeaderProps) {
  const navigate = useNavigate()
  const { permissions } = useAuth()
  const [search, setSearch] = useState<string>('')
  const [searchTarget, _setSearchTarget] = useState<SearchTarget>('People')
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const onClickSearchTarget = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const setSearchTarget = (value: SearchTarget) => {
    _setSearchTarget(value)
    localStorage.setItem('searchTarget', value)
    setAnchorEl(null)
  }

  const onSearch = () => {
    let url = ''

    switch (searchTarget) {
      case 'Companies':
        url = '/companies'
        break

      default:
      case 'People':
        url = '/people'
        break

      case 'Work Orders':
        url = '/work-orders'
        break
    }

    url += '?search=' + encodeURIComponent(search)

    navigate(url)
    setSearch('')
  }

  useEffect(() => {
    const _searchTarget = localStorage.getItem('searchTarget')

    if (_searchTarget && IsSearchTarget(_searchTarget)) {
      _setSearchTarget(_searchTarget)
    }
  }, [])

  return (
    <>
      <Slide appear={false} direction='down' in={!trigger}>
        <AppBar position='fixed' sx={{ paddingLeft: isMobile ? '0px' : '150px' /* '162px' */ }}>
          <Toolbar>
            <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
              <Grid item xs sm='auto'>
                <TextField
                  margin='dense'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={onSearch}>
                          <SearchOutlined color='primary' fontSize='small' />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  label={
                    <>
                      Search{' '}
                      <Link onClick={onClickSearchTarget} sx={{ cursor: 'pointer' }}>
                        {searchTarget}
                      </Link>
                    </>
                  }
                  onChange={e => {
                    setSearch(e.target.value)
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      onSearch()
                    }
                  }}
                  type='search'
                  value={search || ''}
                />
              </Grid>

              {/* User Profile */}
              <Grid item xs>
                <User />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Slide>

      <Menu
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
        }}
        open={Boolean(anchorEl)}
      >
        {permissions.COMPANIES_AND_PEOPLE_ADD_EDIT && (
          <MenuItem
            dense
            onClick={() => {
              setSearchTarget('Companies')
            }}
          >
            Companies
          </MenuItem>
        )}

        {permissions.COMPANIES_AND_PEOPLE_ADD_EDIT && (
          <MenuItem
            dense
            onClick={() => {
              setSearchTarget('People')
            }}
          >
            People
          </MenuItem>
        )}

        {(permissions.WORK_ORDER_DETAILS_ADD_EDIT || permissions.WORK_ORDER_DETAILS_READ_ONLY) && (
          <MenuItem
            dense
            onClick={() => {
              setSearchTarget('Work Orders')
            }}
          >
            Work Orders
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
