import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxDecimal194Message,
  maxDecimal194Value,
  minPositiveDecimal194Message,
  minPositiveDecimal194Value
} from '../constants'
import { PersonDto } from './person'
import { WorkOrderReleaseDto } from './workOrderRelease'
import { WorkOrderReleaseTaskTypeDto } from './workOrderReleaseTaskType'

export class WorkOrderReleaseTaskDto {
  id?: string = undefined
  workToBeDone?: string = undefined
  estimatedHours?: number = undefined
  dueDate?: string = undefined
  completedDateTime?: string = undefined
  taskCanBeClosed: boolean = false
  hoursWorked?: number = undefined
  descriptionOfWorkDone?: string = undefined
  workOrderRelease?: WorkOrderReleaseDto = undefined
  workOrderReleaseTaskType?: WorkOrderReleaseTaskTypeDto = undefined
  responsible: PersonDto[] = []
  readonly_hoursStillNeeded?: number = undefined
  needsEstimation?: number = undefined
}

export const workOrderReleaseTaskValidationSchema = yup.object({
  id: yup.string().nullable(),
  workOrderRelease: yup.object().required('Release is required.'),
  workOrderReleaseTaskType: yup.object().required('Task name is required.'),
  responsible: yup.array().of(yup.object()).required('Responsible is required.').min(1, 'Responsible is required.'),
  workToBeDone: yup
    .string()
    .max(2_000, 'Work To Be Done cannot be more than 2,000 characters.')
    .required('Work To Be Done is required.'),
  estimatedHours: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required('Estimated Hours is required.')
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  dueDate: yup.date().required('Due Date is required.'),
  taskCanBeClosed: yup.boolean(),
  hoursWorked: yup
    .number()
    .min(0.0, 'Hours Worked cannot be less than 0.00.')
    .max(24.0, 'Hours Worked cannot be more than 24.00.')
    .when('taskCanBeClosed', (values, schema) => {
      const taskCanBeClosed = values[0]

      if (taskCanBeClosed) {
        return schema.required('Hours Worked is required.')
      }

      return schema.nullable()
    })
})
