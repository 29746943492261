import { CssBaseline, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { RequirePermissions } from 'components'
import { AuthProvider, LayoutProvider } from 'context'
import { SnackbarProvider } from 'notistack'
import * as pages from 'pages'
import Campaigns from 'pages/campaigns'
import CampaignId from 'pages/campaigns/id'
import Companies from 'pages/companies'
import CompaniesId from 'pages/companies/id'
import Opportunities from 'pages/oppotunities'
import OpportunityDetails from 'pages/oppotunities/id'
import AssetDetails from 'pages/assets/id'
import People from 'pages/people'
import PeopleId from 'pages/people/id'
import Reports from 'pages/reports'
import Assets from 'pages/assets/index'
import TimeEntries from 'pages/timeEntries'
import WorkOrders from 'pages/workOrders'
import WorkOrdersId from 'pages/workOrders/id'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { lightTheme } from 'theme'
import Secrets from 'pages/secrets'
import SecretsId from 'pages/secrets/id'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='account/sign-in' element={<pages.SignIn />} />
      <Route path='account/forgot-password' element={<pages.ForgotPassword />} />
      <Route path='account/reset-password' element={<pages.ResetPassword />} />

      <Route element={<LayoutProvider />}>
        <Route path='*' element={<pages.Blank />} />

        <Route
          path='/campaigns'
          element={
            <RequirePermissions permissions={['SALESPERSON']}>
              <Campaigns />
            </RequirePermissions>
          }
        />

        <Route
          path='/campaigns/:id'
          element={
            <RequirePermissions permissions={['SALESPERSON']}>
              <CampaignId />
            </RequirePermissions>
          }
        />

        <Route
          path='/opportunities'
          element={
            <RequirePermissions permissions={['SALESPERSON']}>
              <Opportunities />
            </RequirePermissions>
          }
        />

        <Route
          path='/opportunities/:id'
          element={
            <RequirePermissions permissions={['SALESPERSON']}>
              <OpportunityDetails />
            </RequirePermissions>
          }
        />

        <Route
          path='/work-orders'
          element={
            <RequirePermissions permissions={['WORK_ORDERS_ADD_EDIT', 'WORK_ORDER_DETAILS_READ_ONLY']}>
              <WorkOrders />
            </RequirePermissions>
          }
        />

        <Route
          path='/work-orders/:id'
          element={
            <RequirePermissions permissions={['WORK_ORDERS_ADD_EDIT', 'WORK_ORDER_DETAILS_READ_ONLY']}>
              <WorkOrdersId />
            </RequirePermissions>
          }
        />

        <Route
          path='/tasks'
          element={
            <RequirePermissions permissions={['TASKS', 'WORK_ORDER_TASKS_ADD_EDIT']}>
              <pages.Tasks />
            </RequirePermissions>
          }
        />

        <Route
          path='/kpis'
          element={
            <RequirePermissions permissions={['TASKS']}>
              <pages.Kpis />
            </RequirePermissions>
          }
        />

        <Route
          path='/time-entries'
          element={
            <RequirePermissions permissions={['TASKS', 'TIME_ENTRIES_ADD_EDIT']}>
              <TimeEntries />
            </RequirePermissions>
          }
        />

        <Route
          path='/time-punches'
          element={
            <RequirePermissions permissions={['TASKS', 'TIME_PUNCHES_ADD_EDIT']}>
              <pages.TimePunches />
            </RequirePermissions>
          }
        />

        <Route
          path='/people'
          element={
            <RequirePermissions permissions={['COMPANIES_AND_PEOPLE_ADD_EDIT']}>
              <People />
            </RequirePermissions>
          }
        />

        <Route
          path='/people/:id'
          element={
            <RequirePermissions permissions={['COMPANIES_AND_PEOPLE_ADD_EDIT']}>
              <PeopleId />
            </RequirePermissions>
          }
        />

        <Route
          path='/companies'
          element={
            <RequirePermissions permissions={['COMPANIES_AND_PEOPLE_ADD_EDIT']}>
              <Companies />
            </RequirePermissions>
          }
        />

        <Route
          path='/companies/:id'
          element={
            <RequirePermissions permissions={['COMPANIES_AND_PEOPLE_ADD_EDIT']}>
              <CompaniesId />
            </RequirePermissions>
          }
        />

        <Route path='/reports' element={<Reports />} />

        <Route
          path='/secrets'
          element={
            <RequirePermissions permissions={['SECRETS_ADD_EDIT', 'SECRETS_READ_ONLY']}>
              <Secrets />
            </RequirePermissions>
          }
        />

        <Route
          path='/assets'
          element={
            <RequirePermissions permissions={['SALESPERSON']}>
              <Assets />
            </RequirePermissions>
          }
        />

        <Route
          path='/secrets/:id'
          element={
            <RequirePermissions permissions={['SECRETS_ADD_EDIT', 'SECRETS_READ_ONLY']}>
              <SecretsId />
            </RequirePermissions>
          }
        />

        <Route
          path='/assets/:id'
          element={
            <RequirePermissions permissions={['SALESPERSON']}>
              <AssetDetails />
            </RequirePermissions>
          }
        />
      </Route>
    </>
  )
)

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={lightTheme}>
        <SnackbarProvider maxSnack={6} autoHideDuration={6000}>
          <AuthProvider>
            <CssBaseline />
            <RouterProvider router={router} />
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
