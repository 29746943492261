import { Add, Edit } from '@mui/icons-material'
import { Button, Card, CardContent, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { WorkOrderDto, WorkOrderInvoiceDto } from 'dtos'
import { WorkOrderInvoicesParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { formatCurrency, formatDate, idIsNotNullUndefinedOrNew, useDebounce } from 'utils'
import * as controllers from 'controllers'
import WorkOrderInvoiceAddEditDialog from './WorkOrderInvoiceAddEditDialog'
import { enqueueSnackbar } from 'notistack'
import { Chip, ChipStatusSelect } from 'components/chips'
import { useAuth } from 'context'
import TablePagination from 'components/table/TablePagination'

interface WorkOrderInvoicesCardProps {
  workOrder: WorkOrderDto
  getWorkOrder: () => void
}

export default function WorkOrderInvoicesCard({ workOrder, getWorkOrder }: WorkOrderInvoicesCardProps) {
  const {
    permissions: { WORK_ORDER_INVOICES_ADD, WORK_ORDER_INVOICES_EDIT }
  } = useAuth()

  const [isGettingWorkOrderInvoices, setIsGettingWorkOrderInvoices] = useState<boolean>(false)
  const [isSavingWorkOrderInvoice, setIsSavingWorkOrderInvoice] = useState<boolean>(false)
  const [parameters, setParameters] = useState<WorkOrderInvoicesParameters>({
    page: 0,
    pageSize: 5,
    statuses: ['isActive', 'needsToBeSent', 'waitingOnPayment']
  })
  const [totalCount, setTotalCount] = useState<number>(0)
  const [workOrderInvoice, setWorkOrderInvoice] = useState<WorkOrderInvoiceDto>(new WorkOrderInvoiceDto())
  const [workOrderInvoiceAddEditDialogOpen, setWorkOrderInvoiceAddEditDialogOpen] = useState<boolean>(false)
  const [workOrderInvoices, setWorkOrderInvoices] = useState<WorkOrderInvoiceDto[]>([])

  useEffect(() => {
    if (workOrder && idIsNotNullUndefinedOrNew(workOrder)) {
      getWorkOrderInvoices(parameters, workOrder)
    }
  }, [parameters, workOrder])

  const getWorkOrderInvoices = useDebounce((parameters: WorkOrderInvoicesParameters, workOrder: WorkOrderDto) => {
    setIsGettingWorkOrderInvoices(true)
    controllers
      .getWorkOrderInvoicesByWorkOrderId(parameters, workOrder.id!)
      .then(response => {
        setWorkOrderInvoices(response.value)
        setTotalCount(response.totalCount ?? 0)
      })
      .finally(() => {
        setIsGettingWorkOrderInvoices(false)
      })
  }, 300)

  return (
    <>
      <WorkOrderInvoiceAddEditDialog
        initialValues={workOrderInvoice}
        isLoading={isSavingWorkOrderInvoice}
        onClose={() => {
          setWorkOrderInvoiceAddEditDialogOpen(false)
        }}
        onSave={values => {
          const requestMethod = values.id ? controllers.updateWorkOrderInvoice : controllers.createWorkOrderInvoice

          setIsSavingWorkOrderInvoice(true)
          requestMethod({ ...values, workOrder })
            .then(() => {
              getWorkOrder()
              setWorkOrderInvoiceAddEditDialogOpen(false)
              enqueueSnackbar('Work Order Invoice Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsSavingWorkOrderInvoice(false)
            })
        }}
        open={workOrderInvoiceAddEditDialogOpen}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm='auto'>
                <Typography fontWeight='bold' variant='h5'>
                  Invoices
                </Typography>
              </Grid>

              {WORK_ORDER_INVOICES_ADD && (
                <Grid item xs={12} sm='auto'>
                  <Button
                    fullWidth
                    onClick={() => {
                      setWorkOrderInvoice(new WorkOrderInvoiceDto())
                      setWorkOrderInvoiceAddEditDialogOpen(true)
                    }}
                    size='small'
                    variant='text'
                  >
                    ADD&nbsp;
                    <Add />
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <ChipStatusSelect
                label='Statuses'
                onChange={value => {
                  setParameters({
                    ...parameters,
                    statuses: value
                  })
                }}
                options={[
                  {
                    label: 'Active',
                    color: 'green',
                    value: 'isActive'
                    // count: 0
                  },
                  {
                    label: 'Needs To Be Sent',
                    color: 'red',
                    value: 'needsToBeSent'
                    // count: 0
                  },
                  {
                    label: 'Waiting on Payment',
                    color: 'purple',
                    value: 'waitingOnPayment'
                    // count: 0
                  },
                  {
                    label: 'Inactive',
                    color: 'gray',
                    value: 'isInactive'
                    // count: 0
                  }
                ]}
                value={parameters.statuses ?? []}
              />
            </Grid>

            {isGettingWorkOrderInvoices && (
              <Grid item container alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                <Grid item>
                  <CircularProgress color='primary' size={50} />
                </Grid>
              </Grid>
            )}

            {!isGettingWorkOrderInvoices &&
              workOrderInvoices.map(workOrderInvoice => (
                <Grid item xs={12} key={workOrderInvoice.id}>
                  <Card variant='outlined'>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
                          <Grid item xs={12} sm='auto' container alignItems='center' spacing={0}>
                            <Grid item xs={12} sm='auto'>
                              <Typography variant='h6' sx={{ pr: 1 }}>
                                {formatCurrency(workOrderInvoice.invoiceAmount!)}
                              </Typography>
                            </Grid>

                            {workOrderInvoice.isActive && (
                              <Grid item>
                                <Chip variant='green'>Active</Chip>
                              </Grid>
                            )}

                            {!workOrderInvoice.isActive && (
                              <Grid item>
                                <Chip variant='gray'>Inactive</Chip>
                              </Grid>
                            )}

                            {workOrderInvoice.needsToBeSent && (
                              <Grid item>
                                <Chip variant='red'>Needs To Be Sent</Chip>
                              </Grid>
                            )}

                            {workOrderInvoice.waitingOnPayment && (
                              <Grid item>
                                <Chip variant='purple'>Waiting on Payment</Chip>
                              </Grid>
                            )}
                          </Grid>

                          {WORK_ORDER_INVOICES_EDIT && (
                            <Grid item xs={12} sm='auto'>
                              <Button
                                onClick={() => {
                                  setWorkOrderInvoice(workOrderInvoice)
                                  setWorkOrderInvoiceAddEditDialogOpen(true)
                                }}
                                size='small'
                                variant='text'
                              >
                                EDIT&nbsp;
                                <Edit />
                              </Button>
                            </Grid>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant='body2'>Needs To Be Sent</Typography>

                          <Typography color='primary' variant='body1'>
                            {formatDate(workOrderInvoice.needsToBeSentDate)}
                          </Typography>
                        </Grid>

                        {workOrderInvoice.sentDate && (
                          <Grid item xs={12}>
                            <Typography variant='body2'>Sent</Typography>

                            <Typography color='primary' variant='body1'>
                              {formatDate(workOrderInvoice.sentDate)}
                            </Typography>
                          </Grid>
                        )}

                        {workOrderInvoice.receivedDate && (
                          <Grid item xs={12}>
                            <Typography variant='body2'>Received</Typography>

                            <Typography color='primary' variant='body1'>
                              {formatDate(workOrderInvoice.receivedDate)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </CardContent>

        <TablePagination
          pagination={{
            count: totalCount,
            page: parameters.page,
            pageSize: parameters.pageSize,
            onPageChange: (page: number) => {
              setParameters({ ...parameters, page })
            },
            onPageSizeChange: (pageSize: number) => {
              setParameters({ ...parameters, pageSize })
            }
          }}
          labelRowsPerPage='Cards per page:'
        />
      </Card>
    </>
  )
}
