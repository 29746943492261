import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material'
import { useAuth } from 'context'
import * as controllers from 'controllers'
import { PersonDto, WorkOrderReleaseTaskTimeEntryDto } from 'dtos'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { hhMmToDecimal } from 'utils'
import * as yup from 'yup'
import LabelField from './LabelField'
import { ExplanationAccordion } from './_template/accordion'

const workOrderReleaseTaskTimeEntryValidationSchema = yup.object({
  descriptionOfWorkDone: yup
    .string()
    .max(2_000, 'Work Done cannot be more than 2,000 characters.')
    .required('Work Done is required.'),
  hoursWorked: yup
    .number()
    .typeError('Hours Worked must be a number.')
    .min(0, 'Hours Worked cannot be less than 0.00.')
    .max(24, 'Hours Worked cannot be more than 24.00.')
    .required('Hours Worked is required.'),
  taskCanBeClosed: yup.boolean(),
  hoursStillNeeded: yup
    .number()
    .typeError('Hours Still Needed must be a number.')
    .min(0, 'Hours Still Needed cannot be less than 0.00')
    .max(999, 'Hours Still Needed cannot be more than 999.99.')
    .when('taskCanBeClosed', (values, schema) => {
      const taskCanBeClosed = values[0]

      if (taskCanBeClosed) {
        return schema.nullable()
      }

      return schema.required('Hours Still Needed is required.')
    })
})

interface WorkOrderReleaseTaskTimeEntryAddEditDialogProps {
  initialValues: WorkOrderReleaseTaskTimeEntryDto
  isLoading?: boolean
  onClose: () => void
  onSave: (values: WorkOrderReleaseTaskTimeEntryDto) => void
  open: boolean
}

export default function WorkOrderReleaseTaskTimeEntryAddEditDialog({
  initialValues,
  isLoading,
  onClose,
  onSave,
  open
}: WorkOrderReleaseTaskTimeEntryAddEditDialogProps) {
  const {
    permissions: { TIME_ENTRIES_ADD_EDIT, CAN_LOG_TIME_FOR_OTHER_USERS }
  } = useAuth()

  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])

  useEffect(() => {
    controllers.getPeople({ page: 0, pageSize: 2000, permission: 'TASKS', statuses: ['isActive'] }).then(response => {
      setTeamMembers(response.value)
    })
  }, [])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validationSchema={workOrderReleaseTaskTimeEntryValidationSchema}
        onSubmit={values => {
          onSave(values)
          console.log('Form Values', values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          validateForm,
          setTouched,
          submitForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Time Entry</DialogTitle>

                  <Grid item xs={12}>
                    <LabelField
                      label='Company'
                      value={values.workOrderReleaseTask?.workOrderRelease?.workOrder?.company?.name ?? ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField
                      label='Work Order'
                      value={
                        '#' +
                        values.workOrderReleaseTask?.workOrderRelease?.workOrder?.workOrderNumber +
                        ' - ' +
                        values.workOrderReleaseTask?.workOrderRelease?.workOrder?.project?.name
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField
                      label='Release'
                      value={values.workOrderReleaseTask?.workOrderRelease?.workOrderReleaseType?.name ?? ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField
                      label='Release Title'
                      value={values.workOrderReleaseTask?.workOrderRelease?.releaseTitle ?? ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField label='Task Type' value={values.workOrderReleaseTask?.workOrderReleaseTaskType?.name ?? ''} />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField label='Estimated Hours' value={values.workOrderReleaseTask?.estimatedHours + '' ?? ''} />
                  </Grid>

                  <Grid item xs={12}>
                    <LabelField label='Work To Be Done' value={values.workOrderReleaseTask?.workToBeDone ?? ''} />
                  </Grid>

                  {CAN_LOG_TIME_FOR_OTHER_USERS && (
                    <Grid item xs={12}>
                      <Autocomplete
                        disabled={isLoading || isSubmitting}
                        disableCloseOnSelect
                        getOptionLabel={teamMember => teamMember.firstName + ' ' + teamMember.lastName}
                        onBlur={handleBlur}
                        onChange={(_e, value) => {
                          setFieldValue('workDoneBy', value)
                        }}
                        options={teamMembers.map(teamMember => ({
                          id: teamMember.id,
                          firstName: teamMember.firstName,
                          lastName: teamMember.lastName
                        }))}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={Boolean(touched.workDoneBy && errors.workDoneBy)}
                            helperText={touched.workDoneBy && (errors.workDoneBy as string)}
                            label='Work Done By'
                          />
                        )}
                        value={
                          values.workDoneBy
                            ? {
                                id: values.workDoneBy.id,
                                firstName: values.workDoneBy.firstName,
                                lastName: values.workDoneBy.lastName
                              }
                            : null
                        }
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || !TIME_ENTRIES_ADD_EDIT}
                      error={Boolean(touched.workDate && errors.workDate)}
                      helperText={touched.workDate && errors.workDate}
                      InputLabelProps={{ shrink: true }}
                      label='Work Date'
                      name='workDate'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='date'
                      value={values.workDate}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(errors.descriptionOfWorkDone)}
                      helperText={errors.descriptionOfWorkDone}
                      label='Work Done'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='descriptionOfWorkDone'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.descriptionOfWorkDone || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading}
                      error={Boolean(touched.hoursWorked && errors.hoursWorked)}
                      helperText={touched.hoursWorked && errors.hoursWorked}
                      label='Hours Worked'
                      name='hoursWorked'
                      onBlur={e => {
                        handleBlur(e)
                        try {
                          const decimalHours = hhMmToDecimal(e.target.value)
                          setFieldValue('hoursWorked', decimalHours)
                        } catch (error) {
                          console.error()
                          setFieldValue('hoursWorked', '')
                        }
                      }}
                      onChange={handleChange}
                      type='text'
                      value={values.hoursWorked}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.taskCanBeClosed}
                          disabled={isLoading}
                          name='taskCanBeClosed'
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e)

                            if (e.target.checked) {
                              setFieldValue('hoursStillNeeded', undefined)
                            }
                          }}
                          value={values.taskCanBeClosed}
                        />
                      }
                      label='Close Task'
                    />
                  </Grid>

                  {!values.taskCanBeClosed && (
                    <Grid item xs={12}>
                      <TextField
                        disabled={isLoading}
                        error={Boolean(touched.hoursStillNeeded && errors.hoursStillNeeded)}
                        helperText={touched.hoursStillNeeded && errors.hoursStillNeeded}
                        label='Hours Still Needed'
                        name='hoursStillNeeded'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='number'
                        value={values.hoursStillNeeded}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading}
                      error={Boolean(touched.travelMiles && errors.travelMiles)}
                      helperText={touched.travelMiles && errors.travelMiles}
                      label='Mileage'
                      name='travelMiles'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      value={values.travelMiles}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} mt={2}>
                  <ExplanationAccordion>
                    Work Date is disabled and defaults to todays date, unless the user has Time Entry Add/Edit permissions.
                    ||Estimated Hours displays the estimated hours, otherwise it will display Needs Estimation until the task
                    has estimated hours.
                  </ExplanationAccordion>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button color='inherit' disabled={isLoading} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={isLoading} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
