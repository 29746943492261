import * as controllers from 'controllers'
import { PersonDto, SignInDto } from 'dtos'
import { createContext, useContext, useEffect, useState } from 'react'

interface PermissionState {
  COMPANIES_AND_PEOPLE_ADD_EDIT: boolean
  DESIGNER: boolean
  DEVELOPER: boolean
  FILES_ACCESS_ADD_EDIT: boolean
  GENERAL_MANAGER: boolean
  HUMAN_RESOURCES: boolean
  PROJECT_MANAGER: boolean
  SALESPERSON: boolean
  TASKS: boolean
  TIME_CLOCK: boolean
  TIME_ENTRIES_ADD_EDIT: boolean
  TIME_PUNCHES_ADD_EDIT: boolean
  USERS_ADD_EDIT: boolean
  WORK_ORDER_DETAILS_ADD_EDIT: boolean
  WORK_ORDER_DETAILS_READ_ONLY: boolean
  WORK_ORDER_INVOICES_ADD: boolean
  WORK_ORDER_INVOICES_EDIT: boolean
  WORK_ORDER_INVOICES_READ_ONLY: boolean
  WORK_ORDER_RELEASES_ADD_EDIT: boolean
  WORK_ORDER_RELEASES_READ_ONLY: boolean
  WORK_ORDER_TASKS_ADD_EDIT: boolean
  WORK_ORDER_TASKS_READ_ONLY: boolean
  SECRETS_ADD_EDIT: boolean
  SECRETS_READ_ONLY: boolean
  CAN_LOG_TIME_FOR_OTHER_USERS: boolean
  CAN_MOVE_TIME_ENTRIES_FOR_OTHER_USERS: boolean
}

export const AuthContext = createContext<{
  permissions: PermissionState
  signIn: (values: SignInDto) => void
  signOut: () => void
  status: 'loading' | 'authorized' | 'unauthorized'
  user: PersonDto | null
} | null>(null)

export default function AuthProvider({ children }: React.PropsWithChildren) {
  // To add a permission to the front end, update the PermissionState interface above, and add the
  // permission here initialized to false.
  // Please note: The added permission MUST be the permission's special identifier.
  const [permissions, setPermissions] = useState<PermissionState>({
    COMPANIES_AND_PEOPLE_ADD_EDIT: false,
    DESIGNER: false,
    DEVELOPER: false,
    FILES_ACCESS_ADD_EDIT: false,
    GENERAL_MANAGER: false,
    HUMAN_RESOURCES: false,
    PROJECT_MANAGER: false,
    SALESPERSON: false,
    SECRETS_ADD_EDIT: false,
    SECRETS_READ_ONLY: false,
    TASKS: false,
    TIME_CLOCK: false,
    TIME_ENTRIES_ADD_EDIT: false,
    TIME_PUNCHES_ADD_EDIT: false,
    USERS_ADD_EDIT: false,
    WORK_ORDER_DETAILS_ADD_EDIT: false,
    WORK_ORDER_DETAILS_READ_ONLY: false,
    WORK_ORDER_INVOICES_ADD: false,
    WORK_ORDER_INVOICES_EDIT: false,
    WORK_ORDER_INVOICES_READ_ONLY: false,
    WORK_ORDER_RELEASES_ADD_EDIT: false,
    WORK_ORDER_RELEASES_READ_ONLY: false,
    WORK_ORDER_TASKS_ADD_EDIT: false,
    WORK_ORDER_TASKS_READ_ONLY: false,
    CAN_LOG_TIME_FOR_OTHER_USERS: false,
    CAN_MOVE_TIME_ENTRIES_FOR_OTHER_USERS: false
  })
  const [status, _setStatus] = useState<'loading' | 'authorized' | 'unauthorized'>('unauthorized')
  const [user, _setUser] = useState<PersonDto | null>(null)

  useEffect(() => {
    checkIfUserSignedIn()
  }, [])

  useEffect(() => {
    if (user && user.permissions) {
      let _permissions = permissions

      user.permissions
        .filter(permission => permission.specialIdentifier)
        .forEach(permission => {
          // @ts-ignore
          _permissions[permission.specialIdentifier!] = true
        })

      setPermissions(_permissions)
    }
  }, [user])

  useEffect(() => {
    console.log(permissions)
  }, [permissions])

  const signIn = ({ email, password }: SignInDto) => {
    _setStatus('loading')
    controllers
      .signIn(email, password)
      .then(({ value }) => {
        setUserAndStatus(value)
      })
      .catch(() => {
        _setStatus('unauthorized')
      })
  }

  const signOut = () => {
    controllers.signOut().then(() => {
      setUserAndStatus(null, 'loading')
      window.location.assign('/account/sign-in')
    })
  }

  // Persist through refreshes
  const checkIfUserSignedIn = () => {
    _setStatus('loading')
    controllers
      .checkIfUserSignedIn()
      .then(({ value }) => {
        setUserAndStatus(value)
      })
      .catch(() => {
        _setStatus('unauthorized')
      })
  }

  const setUserAndStatus = (value: PersonDto | null, status?: 'unauthorized' | 'authorized' | 'loading') => {
    _setUser(value)

    if (status) {
      _setStatus(status)
    } else if (value) {
      _setStatus('authorized')
    } else {
      _setStatus('unauthorized')
    }
  }

  return (
    <AuthContext.Provider
      value={{
        permissions,
        signIn,
        signOut,
        status,
        user
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)!
