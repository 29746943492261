import { Edit } from '@mui/icons-material'
import TimerIcon from '@mui/icons-material/Timer'
import { Button, Grid, MenuItem, TextField } from '@mui/material'
import { WorkOrderReleaseTaskTimeEntryAddEditDialog } from 'components'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListBody, ListFilters, ListHeader, ListRow } from 'components/list'
import { useAuth, useLayout } from 'context'
import * as api from 'controllers'
import * as controllers from 'controllers'
import {
  CompanyDto,
  PersonDto,
  StatusCount,
  WorkOrderDto,
  WorkOrderReleaseTaskDto,
  WorkOrderReleaseTaskTimeEntryDto
} from 'dtos'
import { useQuery } from 'hooks'
import { enqueueSnackbar } from 'notistack'
import { WorkOrderReleaseTasksParameters } from 'parameters'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate, getReleaseNumber, useDebounce, idIsNotNullUndefinedOrNew } from 'utils'
import WorkOrderReleaseTaskAddEditDialog from './workOrders/id/WorkOrderReleaseTaskAddEditDialog'

export default function Tasks() {
  const { setIsLayoutLoading, setTitle } = useLayout()
  const navigate = useNavigate()
  const query = useQuery()
  const { user } = useAuth()

  // Loading state
  const [isGettingTeamMembers, setIsGettingTeamMembers] = useState<boolean>(false)
  const [isGettingProjectManagers, setIsGettingProjectManagers] = useState<boolean>(false)
  const [isGettingCompanies, setIsGettingCompanies] = useState<boolean>(false)
  const [isCreatingTimeEntry, setIsCreatingTimeEntry] = useState<boolean>(false)

  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])
  const [projectManagers, setProjectManagers] = useState<PersonDto[]>([])
  const [companies, setCompanies] = useState<CompanyDto[]>([])

  const [timeEntry, setTimeEntry] = useState<WorkOrderReleaseTaskTimeEntryDto>(new WorkOrderReleaseTaskTimeEntryDto())
  const [timeEntryDialogOpen, setTimeEntryDialogOpen] = useState<boolean>(false)

  const [count, setCount] = useState<number>(0)
  const [tasks, setTasks] = useState<WorkOrderReleaseTaskDto[]>([])

  const [parameters, setParameters] = useState<WorkOrderReleaseTasksParameters>({
    page: 0,
    pageSize: 10,
    teamMemberId: '',
    projectManagerId: '',
    search: query.get('search') ?? '',
    statuses: ['isOpen', 'isComplete', 'needsEstimation', 'needsDueDate'],
    activeOnly: true,
    companyId: '',
    workOrderReleaseId: ''
  })

  const getTasks = useDebounce((parameters: WorkOrderReleaseTasksParameters) => {
    setIsLayoutLoading(true)
    api
      .getWorkOrderReleaseTasks(parameters)
      .then(({ totalCount, value }) => {
        // if (!parameters.includeCompleted) {
        //   value = value.filter(task => !task.isClosed)
        // }
        setCount(totalCount ?? 0)
        setTasks(value)
      })
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 333)

  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([
    { status: 'isOpen', count: 0 },
    { status: 'isComplete', count: 0 },
    { status: 'needsEstimation', count: 0 },
    { status: 'needsDueDate', count: 0 }
  ])

  const findStatusCount = (status: string) => statusCounts.filter(statusCount => statusCount.status === status)[0].count

  // const releaseNumber = getReleaseNumber(task.workOrderRelease!)

  const {
    permissions: { WORK_ORDER_TASKS_ADD_EDIT }
  } = useAuth()
  const [workOrderReleaseTask, setWorkOrderReleaseTask] = useState<WorkOrderReleaseTaskDto>(new WorkOrderReleaseTaskDto())
  const [workOrderReleaseTaskAddEditDialogOpen, setWorkOrderReleaseTaskAddEditDialogOpen] = useState<boolean>(false)
  const [workOrderReleaseTasks, setWorkOrderReleaseTasks] = useState<WorkOrderReleaseTaskDto[]>([])
  const [isSavingWorkOrderReleaseTask, setIsSavingWorkOrderReleaseTask] = useState<boolean>(false)

  useEffect(() => {
    setTitle('Tasks')

    setIsGettingTeamMembers(true)
    api
      .getPeople({ page: 0, pageSize: 999, permission: 'TASKS', statuses: ['isUser'] })
      .then(({ value }) => {
        setTeamMembers(value)
      })
      .catch(() => {})
      .finally(() => {
        setIsGettingTeamMembers(false)
      })

    setIsGettingProjectManagers(true)
    api
      .getPeople({
        page: 0,
        pageSize: 999,
        permission: 'PROJECT_MANAGER',
        statuses: ['isUser']
      })
      .then(({ value }) => {
        setProjectManagers(value)
      })
      .catch(() => {})
      .finally(() => {
        setIsGettingProjectManagers(false)
      })
  }, [])

  useEffect(() => {
    setParameters({
      ...parameters,
      teamMemberId: user!.id
    })
  }, [user])

  useEffect(() => {
    getTasks(parameters)
  }, [parameters])

  useEffect(() => {
    if (parameters.teamMemberId) {
      setIsGettingCompanies(true)
      api
        .getCompanies({ page: 0, pageSize: 999, teamMemberId: parameters.teamMemberId })
        .then(({ value }) => {
          setCompanies(value)
        })
        .catch(() => {})
        .finally(() => {
          setIsGettingCompanies(false)
        })
    }
  }, [parameters.teamMemberId])

  const [isGettingWorkOrderReleaseTasks, setIsGettingWorkOrderReleaseTasks] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)

  // useEffect(() => {
  //   if (workOrder && idIsNotNullUndefinedOrNew(workOrder)) {
  //     getWorkOrderReleaseTasks(parameters, workOrder)
  //   }
  // }, [parameters, workOrder])

  const getWorkOrderReleaseTasks = useDebounce((parameters: WorkOrderReleaseTasksParameters, workOrder: WorkOrderDto) => {
    setIsGettingWorkOrderReleaseTasks(true)
    controllers
      .getWorkOrderReleaseTasksByWorkOrderId(parameters, workOrder.id!)
      .then(response => {
        setWorkOrderReleaseTasks(response.value)
        setTotalCount(response.totalCount ?? 0)
      })
      .finally(() => {
        setIsGettingWorkOrderReleaseTasks(false)
      })
  }, 300)

  return (
    <>
      <WorkOrderReleaseTaskAddEditDialog
        initialValues={workOrderReleaseTask}
        isLoading={isSavingWorkOrderReleaseTask}
        onClose={() => {
          setWorkOrderReleaseTaskAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsSavingWorkOrderReleaseTask(true)

          controllers
            .updateWorkOrderReleaseTask(values)
            .then(() => {
              // getWorkOrder()
              setWorkOrderReleaseTaskAddEditDialogOpen(false)
              enqueueSnackbar('Task Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsSavingWorkOrderReleaseTask(false)
            })
        }}
        open={workOrderReleaseTaskAddEditDialogOpen}
        // workOrder={workOrder}
        workOrderReleaseTasks={workOrderReleaseTasks}
      />
      <WorkOrderReleaseTaskTimeEntryAddEditDialog
        open={timeEntryDialogOpen}
        initialValues={timeEntry}
        isLoading={isCreatingTimeEntry}
        onClose={() => {
          setTimeEntryDialogOpen(false)
          setTimeEntry(new WorkOrderReleaseTaskTimeEntryDto())
        }}
        onSave={values => {
          setIsCreatingTimeEntry(true)
          api
            .createWorkOrderReleaseTaskTimeEntry(values)
            .then(() => {
              enqueueSnackbar('Time Entry Saved Successfully!', { variant: 'success' })
              setTimeEntryDialogOpen(false)
              getTasks(parameters)
            })
            .finally(() => {
              setIsCreatingTimeEntry(false)
            })
        }}
      />

      <List
        pagination={{
          count,
          onPageChange: (page: number) => {
            setParameters({ ...parameters, page })
          },
          onPageSizeChange: (pageSize: number) => {
            setParameters({ ...parameters, pageSize })
          },
          page: parameters.page,
          pageSize: parameters.pageSize
        }}
      >
        <ListHeader>Tasks</ListHeader>

        <ListFilters>
          <TextField
            label='Team Member'
            onChange={e => {
              setParameters({
                ...parameters,
                teamMemberId: e.target.value,
                projectManagerId: '',
                companyId: ''
              })
            }}
            select
            sx={{ minWidth: 240 }}
            value={parameters.teamMemberId || ''}
          >
            {teamMembers.map(teamMember => (
              <MenuItem key={teamMember.id} value={teamMember.id}>
                {teamMember.firstName} {teamMember.lastName}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label='Project Manager'
            onChange={e => {
              setParameters({ ...parameters, projectManagerId: e.target.value })
            }}
            select
            sx={{ minWidth: 240 }}
            value={parameters.projectManagerId || ''}
          >
            {projectManagers.map(projectManager => (
              <MenuItem key={projectManager.id} value={projectManager.id}>
                {projectManager.firstName} {projectManager.lastName}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label='Company'
            onChange={e => {
              setParameters({ ...parameters, companyId: e.target.value })
            }}
            select
            sx={{ minWidth: 240 }}
            value={parameters.companyId || ''}
          >
            {companies.map(company => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </TextField>
        </ListFilters>

        <ListBody>
          {tasks.map(task => (
            <ListRow
              title={
                task.workOrderRelease?.workOrder?.company?.name +
                ' - ' +
                task.workOrderRelease?.workOrder?.project?.name +
                ' - #' +
                task.workOrderRelease?.workOrder?.workOrderNumber +
                ' - ' +
                task.workOrderRelease?.releaseTitle +
                (getReleaseNumber(task.workOrderRelease!) ? ' - ' + getReleaseNumber(task.workOrderRelease!) : '')
              }
              subtitle1={'Due ' + formatDate(task.dueDate)}
              subtitle2={task.workToBeDone!}
              chips={[
                {
                  backgroundColor: '#f1f1f2',
                  color: '#9d9fa4',
                  label: 'Complete',
                  when: Boolean(task.completedDateTime)
                },
                {
                  backgroundColor: '#ffe9ea',
                  color: '#ff8185',
                  label: 'Needs Estimation',
                  when: Boolean(task.needsEstimation)
                }
              ]}
            >
              <React.Fragment>
                <Grid container spacing={1}>
                  <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
                    <Grid item xs={12} sm='auto'>
                      <Button
                        endIcon={<TimerIcon />}
                        onClick={() => {
                          setTimeEntry({
                            ...new WorkOrderReleaseTaskTimeEntryDto(),
                            workOrderReleaseTask: task
                          })
                          setTimeEntryDialogOpen(true)
                        }}
                        size='small'
                        variant='text'
                      >
                        LOG TIME
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm='auto'>
                      {WORK_ORDER_TASKS_ADD_EDIT && (
                        <Button
                          endIcon={<Edit />}
                          onClick={() => {
                            setWorkOrderReleaseTask(task)
                            setWorkOrderReleaseTaskAddEditDialogOpen(true)
                          }}
                          size='small'
                          variant='text'
                        >
                          EDIT
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            </ListRow>
          ))}
        </ListBody>
        <Grid item xs={12}>
          <ExplanationAccordion>
            Needs Estimation will appear at the top of the list and will be ordered according to due date. ||A red badge will
            appear with the count of Tasks that need estimation on the menu Tasks button. || Ordered by Due Date Ascending then
            by Created Date/Time Ascending. ||Tasks will automatically default to the user that is signed in if they have tasks.
            ||The Project Manager dropdown allows the user to search for a project based on the Project Manager. ||The Company
            dropdown allows the user to search for a project based on the Company the project is under. ||Statuses multi-select
            filters the list based on the selected Statuses. ||A green chip will display 'Active' if the Tasks current status is
            "Active". ||A grey chip will display 'Complete' if the current status of the Task is "Complete". ||A red chip will
            display 'Needs Estimation' if the current status of the Task is "Needs Estimation".
          </ExplanationAccordion>
        </Grid>
      </List>
    </>
  )
}
