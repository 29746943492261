import { Add, Edit } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import { Chip, ChipStatusSelect } from 'components/chips'
import { useAuth } from 'context'
import * as controllers from 'controllers'
import { WorkOrderDto, WorkOrderReleaseTaskDto } from 'dtos'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'
import { WorkOrderReleaseTasksParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { formatDate, getReleaseNumber, idIsNotNullUndefinedOrNew, useDebounce } from 'utils'
import WorkOrderReleaseTaskAddEditDialog from './WorkOrderReleaseTaskAddEditDialog'
import TablePagination from 'components/table/TablePagination'

interface WorkOrderReleaseTasksCardProps {
  workOrder: WorkOrderDto
  getWorkOrder: () => void
}

export default function WorkOrderReleaseTasksCard({ workOrder, getWorkOrder }: WorkOrderReleaseTasksCardProps) {
  const {
    permissions: { WORK_ORDER_TASKS_ADD_EDIT }
  } = useAuth()

  const [isGettingWorkOrderReleaseTasks, setIsGettingWorkOrderReleaseTasks] = useState<boolean>(false)
  const [isSavingWorkOrderReleaseTask, setIsSavingWorkOrderReleaseTask] = useState<boolean>(false)
  const [parameters, setParameters] = useState<WorkOrderReleaseTasksParameters>({
    page: 0,
    pageSize: 5,
    statuses: ['isOpen', 'isPastDue'],
    workOrderReleaseId: ''
  })
  const [totalCount, setTotalCount] = useState<number>(0)
  const [workOrderReleaseTask, setWorkOrderReleaseTask] = useState<WorkOrderReleaseTaskDto>(new WorkOrderReleaseTaskDto())
  const [workOrderReleaseTaskAddEditDialogOpen, setWorkOrderReleaseTaskAddEditDialogOpen] = useState<boolean>(false)
  const [workOrderReleaseTasks, setWorkOrderReleaseTasks] = useState<WorkOrderReleaseTaskDto[]>([])

  useEffect(() => {
    if (workOrder && idIsNotNullUndefinedOrNew(workOrder)) {
      getWorkOrderReleaseTasks(parameters, workOrder)
    }
  }, [parameters, workOrder])

  const getWorkOrderReleaseTasks = useDebounce((parameters: WorkOrderReleaseTasksParameters, workOrder: WorkOrderDto) => {
    setIsGettingWorkOrderReleaseTasks(true)
    controllers
      .getWorkOrderReleaseTasksByWorkOrderId(parameters, workOrder.id!)
      .then(response => {
        setWorkOrderReleaseTasks(response.value)
        setTotalCount(response.totalCount ?? 0)
      })
      .finally(() => {
        setIsGettingWorkOrderReleaseTasks(false)
      })
  }, 300)

  return (
    <>
      <WorkOrderReleaseTaskAddEditDialog
        initialValues={workOrderReleaseTask}
        isLoading={isSavingWorkOrderReleaseTask}
        onClose={() => {
          setWorkOrderReleaseTaskAddEditDialogOpen(false)
        }}
        onSave={values => {
          const requestMethod = values.id ? controllers.updateWorkOrderReleaseTask : controllers.createWorkOrderReleaseTask

          setIsSavingWorkOrderReleaseTask(true)
          requestMethod(values)
            .then(() => {
              getWorkOrder()
              setWorkOrderReleaseTaskAddEditDialogOpen(false)
              enqueueSnackbar('Work Order Release Task Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsSavingWorkOrderReleaseTask(false)
            })
        }}
        open={workOrderReleaseTaskAddEditDialogOpen}
        workOrder={workOrder}
        workOrderReleaseTasks={workOrderReleaseTasks}
      />

      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm='auto'>
                <Typography fontWeight='bold' variant='h5'>
                  Tasks
                </Typography>
              </Grid>

              <Grid item xs={12} sm='auto'>
                {WORK_ORDER_TASKS_ADD_EDIT && (
                  <Button
                    endIcon={<Add />}
                    onClick={() => {
                      setWorkOrderReleaseTask(new WorkOrderReleaseTaskDto())
                      setWorkOrderReleaseTaskAddEditDialogOpen(true)
                    }}
                    size='small'
                    variant='text'
                  >
                    ADD
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ChipStatusSelect
                label='Statuses'
                onChange={value => {
                  setParameters({
                    ...parameters,
                    statuses: value
                  })
                }}
                options={[
                  {
                    label: 'Open',
                    color: 'green',
                    value: 'isOpen'
                    // count: 0
                  },
                  {
                    label: 'Past Due',
                    color: 'red',
                    value: 'isPastDue'
                    // count: 0
                  },
                  {
                    label: 'Closed',
                    color: 'yellow',
                    value: 'isClosed'
                    // count: 0
                  }
                ]}
                value={parameters.statuses ?? []}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Release'
                onChange={e => {
                  setParameters({
                    ...parameters,
                    workOrderReleaseId: e.target.value
                  })
                }}
                select
                SelectProps={{
                  displayEmpty: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                value={parameters.workOrderReleaseId || ''}
              >
                <MenuItem value={''}>All Releases</MenuItem>
                {workOrder.workOrderReleases
                  ?.sort((a, b) => (a.workOrderReleaseType?.sequence ?? 0) - (b.workOrderReleaseType?.sequence ?? 0))
                  .map(release => (
                    <MenuItem key={release.id} value={release.id}>
                      {release.releaseTitle + ' - ' + getReleaseNumber(release)}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            {isGettingWorkOrderReleaseTasks && (
              <Grid item container alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                <Grid item>
                  <CircularProgress color='primary' size={50} />
                </Grid>
              </Grid>
            )}

            {!isGettingWorkOrderReleaseTasks &&
              workOrderReleaseTasks.map(workOrderReleaseTask => (
                <Grid item xs={12} key={workOrderReleaseTask.id}>
                  <Card variant='outlined'>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
                          <Grid item xs={12} sm='auto' container alignItems='center' spacing={0}>
                            <Grid item xs={12} sm='auto'>
                              <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                                {workOrderReleaseTask.workOrderReleaseTaskType?.name}
                              </Typography>
                            </Grid>

                            {!workOrderReleaseTask.completedDateTime && (
                              <>
                                <Grid item>
                                  <Chip variant='green'>Open</Chip>
                                </Grid>

                                {moment(workOrderReleaseTask.dueDate!).diff(moment(), 'days') < 0 && (
                                  <Grid item>
                                    <Chip variant='red'>Past Due</Chip>
                                  </Grid>
                                )}
                              </>
                            )}

                            {workOrderReleaseTask.completedDateTime && (
                              <Grid item>
                                <Chip variant='yellow'>Closed</Chip>
                              </Grid>
                            )}
                          </Grid>

                          <Grid item xs={12} sm='auto'>
                            {WORK_ORDER_TASKS_ADD_EDIT && (
                              <Button
                                endIcon={<Edit />}
                                onClick={() => {
                                  setWorkOrderReleaseTask(workOrderReleaseTask)
                                  setWorkOrderReleaseTaskAddEditDialogOpen(true)
                                }}
                                size='small'
                                variant='text'
                              >
                                EDIT
                              </Button>
                            )}
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant='body2'>Release</Typography>

                          <Typography color='primary' variant='body1'>
                            {workOrderReleaseTask.workOrderRelease?.releaseTitle}
                            {' - '}
                            {getReleaseNumber(workOrderReleaseTask.workOrderRelease!)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant='body2'>Responsible</Typography>

                          <Typography color='primary' variant='body1'>
                            <Link component={ReactRouterLink} to={'/people/' + workOrderReleaseTask.responsible[0]?.id}>
                              {workOrderReleaseTask.responsible[0]?.firstName} {workOrderReleaseTask.responsible[0]?.lastName}
                            </Link>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant='body2'>Work To Be Done</Typography>

                          <Typography color='primary' variant='body1'>
                            {workOrderReleaseTask.workToBeDone}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant='body2'>Due Date</Typography>

                          <Typography color='primary' variant='body1'>
                            {formatDate(workOrderReleaseTask.dueDate)}
                          </Typography>
                        </Grid>

                        {workOrderReleaseTask.completedDateTime && (
                          <Grid item xs={12}>
                            <Typography variant='body2'>Completed Date</Typography>

                            <Typography color='primary' variant='body1'>
                              {formatDate(workOrderReleaseTask.completedDateTime)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </CardContent>

        <TablePagination
          pagination={{
            count: totalCount,
            page: parameters.page,
            pageSize: parameters.pageSize,
            onPageChange: (page: number) => {
              setParameters({ ...parameters, page })
            },
            onPageSizeChange: (pageSize: number) => {
              setParameters({ ...parameters, pageSize })
            }
          }}
          labelRowsPerPage='Cards per page:'
        />
      </Card>
    </>
  )
}
