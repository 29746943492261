import { Task } from '@mui/icons-material'
import { Grid, Hidden, Paper, Typography } from '@mui/material'
import React, { Children } from 'react'

export type GuestLayoutProps = {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  title: string
  subtitle?: string
}

export default function GuestLayout({
  children,
  title,
  subtitle,
  handleSubmit
}: React.PropsWithChildren<GuestLayoutProps>) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        component='main'
        sx={{
          height: '100vh',
          width: '100vw',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: '#fff'
        }}
      >
        <Grid
          container
          spacing={1}
          alignItems='center'
          justifyContent='center'
          width='350px'
        >
          <Grid item xs={12} alignItems='center' justifyContent='center'>
            <img
              src='/spudBadgeLogo.png'
              alt='Spud ERP Logo'
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '350px'
              }}
            />
            <Typography variant='h5' align='center' justifyContent='center'>
              {title}
            </Typography>

            {subtitle && (
              <Typography
                variant='caption'
                align='center'
                justifyContent='center'
                sx={{ color: '#7F7F7F' }}
              >
                {subtitle}
              </Typography>
            )}
          </Grid>

          {Children.map(children, child => (
            <Grid item xs={12}>
              {child}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </form>
  )
}
