import { apiClient } from '_app'
import { ApiResponse, OpportunitiesSummaryDto, OpportunityDto } from 'dtos'
import { OpportunitiesParameters } from 'parameters'
import { SoldOpportunitiesParameters } from 'parameters/opportunitiesParameters'
import { URIQuery } from 'utils'

export const getOpportunities = (parameters: OpportunitiesParameters) =>
  apiClient
    .get<ApiResponse<OpportunityDto[]>>(
      '/api/opportunities' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getOpportunitiesPastDueCount = (personId?: string) =>
  apiClient
    .get<ApiResponse<number>>(
      '/api/opportunities/past-due-count' + URIQuery({ personId })
    )
    .then(({ data }) => data)

export const getSoldOpportunities = (parameters: SoldOpportunitiesParameters) =>
  apiClient
    .get<ApiResponse<OpportunityDto[]>>(
      '/api/opportunities/sold' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getOpportunitiesSummary = (parameters: OpportunitiesParameters) =>
  apiClient
    .get<ApiResponse<OpportunitiesSummaryDto>>(
      '/api/opportunities/summary' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getOpportunityById = (id: string) =>
  apiClient
    .get<ApiResponse<OpportunityDto>>('/api/opportunities/' + id)
    .then(({ data }) => data)

export const createOpportunity = (values: OpportunityDto) =>
  apiClient
    .post<ApiResponse<OpportunityDto>>('/api/opportunities', values)
    .then(({ data }) => data)

export const updateOpportunity = (values: OpportunityDto) =>
  apiClient
    .put<ApiResponse<OpportunityDto>>('/api/opportunities', values)
    .then(({ data }) => data)
