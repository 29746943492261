import * as api from 'controllers'
import { TimePunchDto } from 'dtos'
import { useSnackbar } from 'notistack'
import { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './AuthContext'
import { formatDateValue } from 'utils'

export const TimePunchContext = createContext<{
  status: 'loading' | 'in' | 'out'
  punch: () => void
  lastPunch: Date | null
  setLastPunch: React.Dispatch<React.SetStateAction<Date | null>>
} | null>(null)

export default function TimePunchProvider({ children }: React.PropsWithChildren) {
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [timePunches, setTimePunches] = useState<TimePunchDto[]>([])
  const [status, setStatus] = useState<'loading' | 'in' | 'out'>('loading')
  const [lastPunch, setLastPunch] = useState<Date | null>(null)

  const punch = () => {
    setStatus('loading')
    api.createTimePunch().then(() => {
      api
        .getTimePunches({
          page: 0,
          pageSize: 999,
          dateFrom: formatDateValue(new Date())
        })
        .then(({ value }) => {
          setTimePunches(value)
          const punchedInOut = value.length % 2 === 0 ? 'Out' : 'In'
          setStatus(value.length % 2 === 0 ? 'out' : 'in')
          setLastPunch(new Date())

          enqueueSnackbar(`Punched ${punchedInOut} Successfully!`, {
            variant: 'success'
          })
        })
    })
  }

  {
    /*const isFirstSignInOfDay = (lastPunch: Date | null): boolean => {
    if (!lastPunch) {
      return true // If last punch is null, it's the first sign-in
    }
    const today = new Date().setHours(0, 0, 0, 0)
    const lastPunchDate = lastPunch.setHours(0, 0, 0, 0)
    return today > lastPunchDate // Compare today's date with last punch date
  } */
  }

  const checkTimeLapse = () => {
    const now = new Date()

    if (lastPunch && status !== 'loading') {
      const oneDayInMillis = 24 * 60 * 60 * 1000
      if (now.getTime() - lastPunch.getTime() > oneDayInMillis) {
        setStatus('out')
        setLastPunch(null)
      }
    }
  }

  useEffect(() => {
    checkTimeLapse()
    if (user && user.id) {
      api
        .getTimePunches({
          page: 0,
          pageSize: 999,
          personId: user.id,
          dateFrom: formatDateValue(new Date())
        })
        .then(({ value }) => {
          setStatus(value.length % 2 === 0 ? 'out' : 'in')
          setTimePunches(value)
          setLastPunch(new Date())
        })
      // if (isFirstSignInOfDay(lastPunch)) {
      //    punch()
      //  }
    }

    const interval = setInterval(() => {
      checkTimeLapse()
    }, 60 * 60 * 1000)

    return () => clearInterval(interval)
  }, [user])

  return (
    <TimePunchContext.Provider
      value={{
        status,
        punch,
        lastPunch,
        setLastPunch
      }}
    >
      {children}
    </TimePunchContext.Provider>
  )
}

export const useTimePunch = () => useContext(TimePunchContext)!
